
import { defineComponent, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import AchievementService from "@/core/services/AchievementService";
import { roles } from "@/store/stateless/store";

export default defineComponent({
  setup() {
    const store = useStore();

    const displayResponseData = computed(() => {
      return store.getters.getSalesAchievementsRecord;
    });

    onMounted(() => {
      if (!roles.salesDashboard.includes(roles.userId()))
        window.location.href = "/#/404";

      AchievementService.getSalesDashboardAchievements();
      setTimeout(function () {
        window.location.reload();
      }, 120000);
    });

    return {
      roles,
      displayResponseData
    };
  }
});
